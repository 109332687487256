<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        严把医疗质量关，筑牢生命安全线—2024年广东省放射影像专业质控中心工作会议简报
      </div>
      <section>
        <div class="div_p">
          为进一步推动全省放射影像质量标准化、规范化、科学化、同质化，加强放射质量安全管理，完善放射质量管控体系，4月20日，由广东省放射影像专业质量控制中心主办，广东省人民医院承办的“2024年广东省放射影像专业质控中心工作会议”在广州顺利召开。国家放射影像专业质控中心主任委员金征宇教授携秘书孙昊教授和于静老师，国家放射影像质控中心委员、对比剂专业组组长，广东省人民医院医学影像科主任梁长虹教授，广东省放射影像专业质控中心主任刘再毅教授出席会议。广东省放射影像质控中心专家委员会全体委员及各市级质控中心负责人、秘书参加会议。
        </div>
        <div class="div_p">
          回顾展望，筑梦前行。国家放射影像质控中心委员、对比剂专业组组长，广东省人民医院医学影像科主任梁长虹教授致欢迎辞，回顾了广东省放射影像质控中心的发展历程，对存在问题进行坦诚剖析，希望未来国家放射影像质控中心能给予更多支持，坚信在国家放射影像质控中心的带领下，广东省放射影像质控中心将继续扛责上肩、履责于行，助推放射事业走上高质量发展的快车道。国家放射影像专业质控中心主任委员金征宇教授发表致辞，金征宇教授肯定了广东省放射影像质控中心所取得的斐然成绩，对质控中心下一步工作提出具体要求，传达了2024年全国影像质控工作的重点。他强调，医疗质量是医疗工作的重点核心内容，通过识别和解决薄弱环节和关键问题，不断完善放射医疗质量管理的基础工作，聚焦重点、同质共建，助力全国放射影像质控工作更上一台阶。
        </div>
        <div>
          <img src="@/assets/GDS2024/1.png" alt="">
        </div>
        <div class="div_tit">
          梁长虹教授致辞
        </div>
        <div>
          <img src="@/assets/GDS2024/2.png" alt="">
        </div>
        <div class="div_tit">
          金征宇教授致辞
        </div>
        <div class="div_p">
          聚焦质量，明确方向。国家放射影像质控中心副主任薛华丹教授为广东省质控同道介绍了2024年质控中心重点工作，传达了国家卫健委“向死亡学习、向错误学习”的号召，重点介绍了危急值上报的重要性，倡导在广东省内建立更为紧密有效的质控工作“织网”，探索国家－省－市质控中心联合探索破壁工作新模式。国家放射影像质控中心秘书孙昊教授进行放射影像质控指标内涵和建立流程的宣讲，重点介绍质控组织体系建设、NCIS放射影像质控指标的调研和分析，以及未来新型工作模式的探讨。另外，孙昊教授还对放射影像专业医疗质量控制指标（2024版）做了解读，搭建年度内工作的具体实施路径，为共同促进放射质量管理规范化、质量数据精细化、质量水平区域同质化的发展指明了方向。
        </div>
        <div>
          <img src="@/assets/GDS2024/3.png" alt="">
        </div>
        <div class="div_p">
          上下同欲，精确谋划。刘再毅教授代表广东省放射影像质控中心对2023年的工作情况进行总结汇报，并对2024年工作进行安排部署，包括完善省质控组织体系建设、推进全省放射影像检查互认以及利用质控信息平台开展质量安全调研，旨在上下同步、强基固本，平台提优、共同发展，为省内质控工作的高标落地起到积极的推动作用。刘主任还表示，省质控中心将全力配合完成国家质控中心布置的工作任务，为持续提高医疗质量，保障医疗安全交出满意答卷。
        </div>
        <div class="div_p">
          规范引领，真抓实干。会议第二个环节，与会专家围绕“国家质控指标、危急值在广东推进实施的问题及解决方案”和“如何推进广东省放射影像质控工作”两个议题进行了深入的讨论。金征宇教授和孙昊教授就我省放射影像质控专家提出的问题进行了详细的解答，并结合实际，全面梳理了我们的工作思路。
        </div>
        <div>
          <img src="@/assets/GDS2024/4.png" alt="">
          <img src="@/assets/GDS2024/5.png" alt="">
        </div>
        <div class="div_tit">
          广东省质控专家就探讨的议题发表个人意见
        </div>
        <div>
          <img src="@/assets/GDS2024/6.png" alt="">
          <img src="@/assets/GDS2024/7.png" alt="">
        </div>
        <div class="div_tit">
          金征宇教授和孙昊教授做一一解答
        </div>
        <div class="div_p">
          精进臻善正当时，惟实励新共发展。医疗质量安全直接关系到人民群众的获得感、安全感，是人民健康的重要保障。本次工作会议使广东省放射影像质控专家对质控中心职能、质控中心的工作内容和计划有了深入了解。在未来，希望广东省放射影像质控专家携手并进，凝共识、抓落实、谋创新，共同推进我省放射影像质控工作的开展，提升质控工作精细化、科学化、规范化程度。
        </div>
        <div>
          <img src="@/assets/GDS2024/8.png" alt="">
        </div>
      </section>
    </div>


  </div>
</template>

<script>
export default {
name:"GDS2024"
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>